/*function f_resa_step_2() {
  var selectStep2 = $('.resa-step-2 .item .selectpicker-wp');
  selectStep2.addClass('inactive');
  var checkboxStep2 = $('.resa-step-2 .item .checkboxradio-wp input');
  checkboxStep2.on('change',function(){
    $(this).parents().eq(1).next().toggleClass('inactive');
  });
  $('.resa-step-2 .item.select-active .checkboxradio-wp input').trigger('click');
  $('.more-info-trigger').on('click',function(){
    return false;
  });
}*/

// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#header .row-wp').toggle();
  });
}

// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 576) {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      $(this).next().toggle();
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      event.stopPropagation();
      return false;
    });
    $('html').click(function() {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

function resa_toggle(){
  var $parent = $('#resa-trigger');
  var $btn  = $('#resa-trigger .btn-a');
  var $form = $('#resa-inner-wp');
  var $btnToggle = $('#trigger-resa-nav, .trigger-resa-nav');
  $btn.on('click',function(){
    $parent.hide();
    $form.show();
  });
  $btnToggle.on('click',function(){
    $(this).toggleClass('open');
    $form.toggle();
  });
}

function f_Carrosserie(){
    $("#carrosserie").on('change', function() {
        if ($(this).val() !== '') {
            $('.resa-car').hide();
            $('.resa-car[data-carrosserie=' + $(this).val() + ']').show();
        } else {
            $('.resa-car').show();
        }

        $('#search-count').text($('.resa-step-1:visible:not(.unavailable)').length);
    });
    $("#carrosserie").trigger('change');
}
f_Carrosserie();


function f_resa_options() {
    $('#options input[type=checkbox]').on('change', function () {
        checkbox($(this));
        request();
    });

    $('#options .quantite select').on('change', function () {
        $(this).parent().parent().parent().parent().find('input').prop('value', $(this).val());
        request();
    });

    // Initialisation
    request();
    $('#options input[type=checkbox]').each(function () {
        checkbox($(this));
    });

    function checkbox(self) {
        var select = self.parent().parent().find('select');
        var quantite = self.parent().parent().find('.quantite select');
        if (self.is(":checked")) {
            select.prop('disabled', false);
            select.parent().parent().parent().removeClass('disabled');
        } else {
            select.prop('disabled', true);
            select.parent().parent().parent().addClass('disabled');
        }
        self.prop('value', quantite.val());
    }

    function request() {
        $.ajax({
            method: "POST",
            url: $("#options").data('ajax-url'),
            data: $("#devis").serialize(),
            success: function(data) {
                $("#total").text(data.total);
                $("#options-montant").text(data.options);
                var options_element = $("#options-montant").parent().parent();
                if (data.options == 0) {
                    options_element.hide();
                } else {
                    options_element.show();
                }
                $("#assurances-montant").text(data.assurances);
                var assurances_element = $("#assurances-montant").parent().parent();
                if (data.assurances == 0) {
                    assurances_element.hide();
                } else {
                    assurances_element.show();
                }
                $("#reductions-montant").text(data.reduction_montant);
                var reduction_element = $("#reductions-montant").parent().parent();
                if (data.reduction_montant == 0) {
                    reduction_element.hide();
                } else {
                    reduction_element.show();
                }
                var promotions = '';
                if (data.promotions) {
                    promotions = '* ';
                    data.promotions.forEach(function(promotion){
                        promotions += promotion.nom + ' : ' + promotion.extrait + ' ; ';
                    });
                }
                $("#reductions-description").text(promotions);
            }
        });
    }

}

if ($('#options').length > 0) {
    f_resa_options();
}

function nav_form_resa_blink() {
    $('.resa-anchor').on('click', function () {
        /*$('#trigger-resa-page').trigger('click');*/
        $('#resa-search').fadeOut(500).fadeIn(500).fadeOut(500).fadeIn(500);
    });
}


// Initialisation
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
resa_toggle();
f_Carrosserie();
nav_form_resa_blink();
