// jQuery assets
import '/theme/javascript/partials/jquery.js';
import '/theme/javascript/partials/jquery-datepicker.js';
import '/theme/javascript/partials/jquery-magnificpopup.js';
//import '/theme/javascript/partials/jquery-parallax.js';
import '/theme/javascript/partials/jquery-parsleyjs.js';
import '/theme/javascript/partials/jquery-slick.js';
import '/theme/javascript/partials/jquery-stickykit.js';

// Bootstrap assets
import '/theme/javascript/partials/bootstrap.js';
import '/theme/javascript/partials/bootstrap-modal.js';
import '/theme/javascript/partials/bootstrap-popover.js';
import '/theme/javascript/partials/bootstrap-selectpicker.js';
//import '/theme/javascript/partials/bootstrap-sliderpicker.js';
//import '/theme/javascript/partials/bootstrap-tooltip.js';

// Google assets
import '/theme/javascript/partials/google-font.js';
import '/theme/javascript/partials/google-map.js';

// Other assets
import '/theme/javascript/partials/aos.js';

// Modules
import '/theme/javascript/modules/navigation.js';
import '/theme/javascript/modules/helper.js';

// SCSS
import '/theme/scss/global.scss';
